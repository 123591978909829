// react
import { useState, useRef } from 'react';

// TO DO:
import LoadingBox from './three-loading';

// drei
import { useAnimations, useGLTF } from '@react-three/drei';

// other
import { useEffect } from 'react';

function Dreamer({
  //deconstructed props
  model,

  animationNames,
  selectedAnimation,
  isPaused,
  //...
}) {
  //const loader = new GLTFLoader();

  //load animations for later use
  const { scene, animations } = useGLTF(model);
  const { actions } = useAnimations(animations, scene);

  //const [gltf, setGltf] = useState(null);

  const prevAnimationRef = useRef();

  //non abstract loader for backgrounds

  useEffect(() => {
    if (scene) {
      scene.traverse(function (object) {
        if (object.isMesh) {
          object.castShadow = true;
          object.receiveShadow = true;
        }
      });
    }
  }, [scene]);

  useEffect(() => {
    if (actions) {
      // because it's an index can't just check for plain existance
      // index 0 is falsy. So check for literal null instead
      if (prevAnimationRef.current != null) {
        // if previous animation exists.. stop it
        actions[animationNames[prevAnimationRef.current].animationName].stop();
      }

      // save current animation to be used above on next fire
      prevAnimationRef.current = selectedAnimation;

      // play new selected animation after stopping previous
      actions[animationNames[selectedAnimation].animationName].play();
    }
  }, [selectedAnimation, actions, animationNames]);

  useEffect(() => {
    actions[animationNames[selectedAnimation].animationName].paused = isPaused;
  }, [isPaused, selectedAnimation, actions, animationNames]);

  return (
    <>
      {scene && (
        <primitive
          castShadow={true}
          object={scene}
          scale={[0.04, 0.04, 0.04]}
          rotation={[0, 3.1, 0]}
        />
      )}
    </>
  );
}

export default Dreamer;
