import logo from './logo.svg';
import './App.css';

// react
import React, { Suspense, useState, useEffect, useRef } from 'react';

// keypress hook
import useKeypress from 'react-use-keypress';

// fiber
import { Canvas, useLoader } from '@react-three/fiber';

// drei
import { OrbitControls, PerspectiveCamera } from '@react-three/drei';

// fiber
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

import { useAnimations, useGLTF } from '@react-three/drei';

// custom components
import Dreamer from './components/dreamer';
import Skybox from './components/skybox';

import ControlOverlay from './components/controloverlay';

import { domainFromUrl } from './functions/domainFromUrl';

// animationNames indexes must match that of the "actions" inside gltf
// returned from "useAnimations" in dreamer file {..., actions, ...}
const animationNames = [
  { animationName: 'Idle 01', optionName: 'Idle' },
  { animationName: 'Death 01', optionName: 'Death' },
  { animationName: 'Death Pose 01', optionName: 'Dead' },
  { animationName: 'Eat 01', optionName: 'Eat' },
  { animationName: 'Run 01', optionName: 'Run' },
  { animationName: 'Attack 01', optionName: 'Attack' },
  { animationName: 'Get Hit 01', optionName: 'Flinch' },
  { animationName: 'Walk 01', optionName: 'Walk' },
];

// background map = matched indexes
const backgroundNames = [
  'Aqua Sky Ruins',
  'Dream Dunes',
  'Purple Sky Ruins',
  'Dusk Ruins',
  'Lavender Cloudy',
  'Pink City',
  'Purple Night',
  'Pink Sky',
  'Blue Sky',
  'Dusk City',
  'Pink Starburst City',
  'Blue Starburst City',
  'Wavy',
  'Pink Cloudy',
  'Aqua City',
];

const backgroundData = [
  //ground colors are CLOSE but not perfect
  { path: '/new_skyboxes/skybox1.glb', groundColor: '#3e2e84' },

  { path: '/new_skyboxes/skybox2.glb', groundColor: '#00ED91' },
  { path: '/new_skyboxes/skybox3.glb', groundColor: '#5dbf89' },

  { path: '/new_skyboxes/skybox4.glb', groundColor: '#181c5b' },
  { path: '/new_skyboxes/skybox5.glb', groundColor: '#e08382' },
  { path: '/new_skyboxes/skybox6.glb', groundColor: '#181c5b' },
  { path: '/new_skyboxes/skybox7.glb', groundColor: '#4522A0' },
  { path: '/new_skyboxes/skybox8.glb', groundColor: '#e8a951' },

  { path: '/new_skyboxes/skybox9.glb', groundColor: '#01649F' },
  { path: '/new_skyboxes/skybox10.glb', groundColor: '#545454' },

  { path: '/new_skyboxes/skybox11.glb', groundColor: '#504B4A' },

  { path: '/new_skyboxes/skybox12.glb', groundColor: '#4135a0' },
  { path: '/new_skyboxes/skybox13.glb', groundColor: '#9a31c6' },
  { path: '/new_skyboxes/skybox14.glb', groundColor: '#a8d4dd' },

  { path: '/new_skyboxes/skybox15.glb', groundColor: '#303030' },
];

const Lights = ({ skybox }) => {
  const ref = useRef();
  //useHelper(ref, DirectionalLightHelper, 1)

  return (
    <>
      {/* TODO - add groundColor from backroundData */}
      <ambientLight color={'white'} intensity={0.1} />

      <hemisphereLight
        //position={[0, 2.5, 5]}
        color={'white'}
        intensity={0.1}
      />

      <pointLight color={'white'} intensity={0.05} position={[-5, 6, 5]} />

      <pointLight color={'white'} intensity={0.05} position={[0, 6, 5]} />

      <pointLight color={'white'} intensity={0.05} position={[5, 6, 5]} />

      {/* <pointLight
        color={'white'}
        intensity={0.05}
        position={[5, 2.5, -5]}
      /> */}

      <directionalLight
        ref={ref}
        intensity={0.5}
        position={[5, 20, 5]}
        shadow-mapSize-width={2048}
        shadow-mapSize-height={2048}
        shadow-camera-far={100}
        shadow-camera-left={-10}
        shadow-camera-right={10}
        shadow-camera-top={10}
        shadow-camera-bottom={-10}
        castShadow
      />

      <directionalLight
        ref={ref}
        intensity={0.3}
        position={[0, 10, -5]}
        shadow-mapSize-width={2048}
        shadow-mapSize-height={2048}
        shadow-camera-far={100}
        shadow-camera-left={-10}
        shadow-camera-right={10}
        shadow-camera-top={10}
        shadow-camera-bottom={-10}
        //castShadow
      />

      <directionalLight
        ref={ref}
        intensity={0.5}
        position={[-5, 10, 4]}
        shadow-mapSize-width={2048}
        shadow-mapSize-height={2048}
        shadow-camera-far={100}
        shadow-camera-left={-10}
        shadow-camera-right={10}
        shadow-camera-top={10}
        shadow-camera-bottom={-10}
        //castShadow
      />
    </>
  );
};

function DreamerElement({ path, setScene, setActions }) {
  const { scene, animations } = useGLTF(path);
  const { actions } = useAnimations(animations, scene);

  if (scene) setScene(scene);
  if (actions) setActions(actions);
  return <> </>;
}

function App() {
  // indexes
  const [selectedAnimation, setSelectedAnimation] = useState(0); // 7 = walk
  const [selectedSkybox, setSelectedSkybox] = useState(randomBackground()); // background index
  const [bgLoaded, setBgLoaded] = useState(false);

  const [dreamerURL, setDreamerURL] = useState(null);

  const [skybox, setSkybox] = useState(null);

  const [groundColor, setGroundColor] = useState('#ffffff');

  // animation state
  const [isPaused, setIsPaused] = useState(false);

  //key that Bitlectro passes for correct dreamer. and origin of requester (Grabs iframe domain)
  const [origin, setOrigin] = useState(
    window.location.ancestorOrigins
      ? domainFromUrl(window.location.ancestorOrigins[0])
      : undefined
  );
  const [key, setKey] = useState(null);
  const [waitForKey, setWaitForKey] = useState(true);

  const [waitForCheck, setWaitForCheck] = useState(true);

  //Dont display GUI for unclaimed dreamers

  const [targetY, setTargetY] = useState(2.5); //Camera target (To change for pfp function)

  //blob to save local gltf file
  const [blob, setBlob] = useState(null);

  function randomBackground() {
    //should return random 0 - 15
    return Math.floor(Math.random() * backgroundData.length);
  }

  // spacebar animation toggle
  useKeypress(' ', () => {
    setIsPaused(!isPaused);
  });

  useEffect(() => {
    if (window.location !== window.parent.location) {
      setWaitForKey(true);
      setWaitForCheck(true);
      const queryString = window.location.search;

      const urlParams = new URLSearchParams(queryString);

      const waitFor = urlParams.get('waitFor');

      if (waitFor) {
        setWaitForKey(true);
        setWaitForCheck(true);
      } else {
        setWaitForKey(false);
        setWaitForCheck(true);
      }
    } else {
      // The page is not in an iframe

      setWaitForCheck(true);
      setWaitForKey(false);
    }
  }, []);

  useEffect(() => {
    function handleMessage(event) {
      // IMPORTANT: check the origin of the data!
      if (
        event?.origin.startsWith('https://opensea.io') ||
        event?.origin.startsWith('https://looksrare.org') ||
        event?.origin.startsWith('https://bitlectrolabs.com') ||
        event?.origin.startsWith('www.bitlectrolabs.com') ||
        event?.origin.startsWith('https://janeway.bitlectrolabs.com') ||
        event?.origin.startsWith('https://dev.mog.garden:3000') ||
        event?.origin.startsWith('https://vxviewer.bitlectrolabs.com') ||
        event?.origin.startsWith('https://ar-test.bitlectrolabs.com')
      ) {
        setOrigin(domainFromUrl(event.origin.toLowerCase()));

        // The data was sent from your site.

        if (event.data.type === 'unwrap success vx mint') {
          setSkybox(randomBackground());
          setSelectedAnimation(4);
        }
        if (event.data.type === 'api key') {
          setWaitForCheck(true);
          setKey(event.data.key);
          setWaitForKey(true);

          if (!event.data.exists) {
            setSelectedAnimation(2);
          }
        }
        if (event.data.type === 'start vx mint') setSelectedAnimation(0);

        if (event.data.type === 'waiting for aproval vx mint')
          setSelectedAnimation(7);

        if (event.data.type === 'cancel vx mint') setSelectedAnimation(2);
      }
    }

    window.removeEventListener('message', handleMessage);
    window.addEventListener('message', handleMessage);
  }, [
    setKey,
    origin,
    key,

    setSelectedAnimation,

    setSkybox,
    selectedAnimation,
    skybox,
  ]);

  useEffect(() => {
    // setOrigin(undefined);
    //get tokenId from url && ignore starting '/'
    if (
      (window.location.origin !== 'https://opensea.io' &&
        !origin &&
        window.location.origin !== 'https://vxviewer.bitlectrolabs.com' &&
        !origin &&
        !origin &&
        window.location.origin !== 'http://localhost:3000' &&
        !origin &&
        window.location.origin !== 'https://mog.garden:3000' &&
        window.location.origin !== 'https://ar-test.bitlectrolabs.com') ||
      (origin &&
        (origin === 'mog.garden' || origin === 'bitlectrolabs.com') &&
        !key)
    ) {
      //  setWaitForKey(true);
    }

    let tokenId = window.location.pathname.substring(1);

    if ((tokenId && !waitForKey) || (tokenId && key && waitForKey)) {
      fetch(`https://dreamerapi.bitlectrolabs.com/dreamersvx/gltf/${tokenId}`, {
        mode: 'cors',
        headers: { 'X-API-KEY': key || '' },
      })
        .then((response) => {
          return response.json();
        })
        .then(async (data) => {
          setDreamerURL(data.gltf_url);
          //fetch gltf file
          const response = await fetch(data.gltf_url);
          const blobFile = await response.blob();
          setBlob(blobFile);
        });
    }
  }, [key, origin, waitForKey]);

  useEffect(() => {
    const loader = new GLTFLoader();
    loader.load(backgroundData[selectedSkybox].path, (gltf) => {
      setSkybox(gltf);

      setGroundColor(backgroundData[selectedSkybox].groundColor);
    });
  }, [selectedSkybox]);

  return (
    <>
      <div style={{ width: '100vw', height: '100vh' }}>
        {/* control overlay */}
        {dreamerURL && skybox && waitForCheck && (
          <ControlOverlay
            setBlob={setBlob}
            blob={blob}
            selectAnimation={setSelectedAnimation}
            selectSkybox={setSelectedSkybox}
            currentAnimation={selectedAnimation}
            currentSkybox={selectedSkybox}
            animationNames={animationNames}
            backgroundNames={backgroundNames}
            setIsPaused={setIsPaused}
            isPaused={isPaused}
            setKey={setKey}
            origin={origin}
            setTargetY={setTargetY}
          />
        )}

        <Canvas colorManagement shadows>
          <PerspectiveCamera makeDefault position={[-1, 2.5, 5]} fov={50} />

          {/* <ambientLight intensity={0.1}/> */}
          {dreamerURL && skybox && waitForCheck && (
            <>
              <Skybox
                skybox={skybox}
                groundColor={groundColor}
                selectedSkybox={selectedSkybox}
                backgroundData={backgroundData}
              />
              <Lights skybox={selectedSkybox} />
            </>
          )}

          {skybox && dreamerURL && waitForCheck && (
            <Dreamer
              blob={blob}
              setBlob={blob}
              model={dreamerURL}
              animationNames={animationNames}
              selectedAnimation={selectedAnimation}
              isPaused={isPaused}
            />
          )}

          {/* controls */}
          <OrbitControls
            minDistance={7}
            maxDistance={14}
            enablePan={false}
            maxPolarAngle={Math.PI / 2 + 0.16}
            target={[0, targetY, 0]}
          />
        </Canvas>
      </div>
    </>
  );
}

export default App;
