import { useState, useEffect } from 'react';

import Select from 'react-select';

var isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;

function ControlOverlay({
  selectAnimation,
  currentAnimation,
  animationNames,

  selectSkybox,
  currentSkybox,
  backgroundNames,

  //animation pause / play toggle
  setIsPaused,
  isPaused,

  //local file save
  blob,

  //origin where request is coming from
  origin,
}) {
  const [skyboxOptions, setSkyboxOptions] = useState([]);
  const [animationOptions, setAnimationOptions] = useState([]);

  useEffect(() => {
    let forSkybox = backgroundNames.map((item, index) => {
      return { value: index, label: item };
    });

    let forAnimation = animationNames.map((item, index) => {
      return { value: index, label: item.optionName };
    });

    setSkyboxOptions(forSkybox);
    setAnimationOptions(forAnimation);
  }, [animationNames, backgroundNames]);

  function handleBackgroundChange(option) {
    selectSkybox(option.value);

    setShowControls(false);
  }

  function handleAnimationChange(option) {
    selectAnimation(option.value);

    setShowControls(false);
  }

  //UI Logic
  const [showControls, setShowControls] = useState(false);

  function handleControlToggle() {
    setShowControls(!showControls);
  }

  return (
    <div className='ui-con'>
      <img
        className='control-button'
        src='/vx-options-button.svg'
        alt='Options'
        height='30px'
        width='30px'
        onClick={handleControlToggle}
      />

      {showControls && (
        <div className='controls-wrapper'>
          <h3 className='control-title'>Background</h3>
          <Select
            value={skyboxOptions[currentSkybox]}
            options={skyboxOptions}
            onChange={handleBackgroundChange}
            isSearchable={false}
          />

          <div className='spacer'></div>

          <span
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <h3 className='control-title'>Animation</h3>

            <button
              className='pause-play-button'
              onClick={() => setIsPaused(!isPaused)}
            >
              <img
                src={
                  !isPaused ? 'pause-button-image.svg' : 'play-button-image.svg'
                }
                alt='Pause / Play Animation'
                height='12px'
                width='12px'
              />
            </button>
          </span>

          <Select
            value={animationOptions[currentAnimation]}
            options={animationOptions}
            onChange={handleAnimationChange}
            isSearchable={false}
          />

          <div className='spacer'></div>

          {!isFirefox && origin !== 'opensea.io' && origin !== 'looksrare.org' && (
            <span
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <h3 className='control-title'>Save GLTF</h3>

              <button
                className='download-button'
                onClick={() => {
                  let tokenId = window.location.pathname.substring(1);
                  let url = window.URL.createObjectURL(blob);
                  let a = document.createElement('a');
                  a.href = url;
                  a.download = `dreamer_${tokenId}.gltf`;
                  a.click();
                }}
              >
                <img
                  style={{
                    filter: 'brightness(0) invert(1)',
                    WebkitFilter: 'brightness(0) invert(1)',
                  }}
                  src='download-button.svg'
                  alt='Save GLTF'
                  height='12px'
                  width='12px'
                />
              </button>
            </span>
          )}
        </div>
      )}
    </div>
  );
}

export default ControlOverlay;
